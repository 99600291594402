import baseService from './baseService'

export const dashboardService = {
  fetchResultListData,
  fetchGroupReportListData,
  standDashboardHomePageData,
  fetchDataForResultPageDropDowns,
  fetchDataForGroupReportPageDropDowns,
  standDashboardHomeByAssessment,
  downloadExcel,
  fetchRequestedFilters,
  getLearningResources,
  agreeDpa
}

function standDashboardHomePageData () {
  return baseService.getRequest('data/getStandardDashboardHomePageData')
}

function standDashboardHomeByAssessment (data) {
  return baseService.postRequest('data/getStandardDashboardHomeByAssessment', data)
}

function fetchResultListData (data) {
  return baseService.postRequest('data/tableData', data)
}

function fetchGroupReportListData (data) {
  return baseService.postRequest('data/groupReportTableData', data)
}

function fetchDataForGroupReportPageDropDowns () {
  return baseService.getRequest('data/groupReportDropDownData')
}

function fetchDataForResultPageDropDowns () {
  return baseService.getRequest('data/dropDownData')
}

function downloadExcel (data) {
  return baseService.postRequest('data/saveRequestedFilters', data)
}
function fetchRequestedFilters () {
  return baseService.getRequest('data/getRequestedFilters')
}

function agreeDpa (data) {
  return baseService.postRequest('data/agreeDpa', data)
}

function getLearningResources () {
  return baseService.getRequest('data/result/learningResources')
}
